import React from 'react';
import { useNavigate } from 'react-router-dom';

import ControledDialog from 'components/_common/Dialog/ControledDialog';
import Button from 'components/_common/Button';

const ModalSuccesfullyPaidInvoice = ({ open, setOpen, redirectUrl, closeTo = null }) => {
  const navigate = useNavigate();

    return (
        <ControledDialog
            title="Paid succesfully"
            Submit={<Button onClick={() => window?.open(redirectUrl, '_blank')}>View Invoice</Button>}
            setOpen={setOpen}
            open={open}
            cancelText="Close"
            onClose={() => navigate(closeTo)}
        >
            <div>Your payment has been successful and your service will be processed shortly.</div>
        </ControledDialog>
    );
};

export default ModalSuccesfullyPaidInvoice;
