import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import ResidentialService from 'api/services/ResidentialService';
import { selectAnalytics } from './logSlice';
import ServicesService from 'api/services/ServicesService';
import { convertToDollar } from 'helpers/payments';
import { service_types } from 'helpers/services';
import Label from 'components/_common/Label';

export const fetchResidentialSummary = createAsyncThunk(
    'fetchResidentialSummary',
    async () => {
        const response = await ResidentialService.summary();
        return response.data;
    },
    {
        condition: (_, { getState }) => {
            // @ts-ignore
            const { loading } = getState().servicesState.summary;
            return !loading;
        },
    },
);

export const fetchServicesSummary = createAsyncThunk(
    'fetchServicesSummary',
    async params => {
        const response = await ServicesService.summary(params);
        const active = [];
        const overdue = [];
        const other = [];
        response?.data?.data.forEach(i => {
            if (i.service_status === 'overdue') {
                return overdue.push(i);
            }
            if (i.service_status === 'active') {
                return active.push(i);
            }
            return other.push(i);
        });
        const data = [...overdue, ...active, ...other];

        return {
            ...response.data,
            data,
        };
    },
    {
        condition: (_, { getState }) => {
            // @ts-ignore
            const { loading } = getState().servicesState.services;
            return !loading;
        },
    },
);
export const fetchServiceById = async id => {
    const response = await ServicesService.summary({ service_id: id });
    return response.data;
};

export const fetchActiveServices = createAsyncThunk('fetchActiveServices', async () => {
    const response = await ServicesService.activeServices();
    return response.data;
});

const ServicesSlice = createSlice({
    name: 'ServicesSlice',
    initialState: {
        summary: {
            loading: false,
            data: null,
            error: null,
        },
        services: {
            loading: false,
            data: null,
            error: null,
            total: 0,
        },
        activeServices: {
            loading: false,
            data: [],
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchResidentialSummary.pending, state => {
                state.summary.loading = true;
            })
            .addCase(fetchResidentialSummary.fulfilled, (state, action) => {
                state.summary.loading = false;
                state.summary.data = action.payload.data;
            })
            .addCase(fetchResidentialSummary.rejected, (state, action) => {
                state.summary.loading = false;
                state.summary.error = action.error;
            });
        builder
            .addCase(fetchServicesSummary.pending, state => {
                state.services.loading = true;
            })
            .addCase(fetchServicesSummary.fulfilled, (state, action) => {
                state.services.loading = false;
                state.services.data = action.payload.data;
                state.services.total = action.payload.total_count;
            })
            .addCase(fetchServicesSummary.rejected, (state, action) => {
                state.services.loading = false;
                state.services.error = action.error;
            });
        builder
            .addCase(fetchActiveServices.pending, state => {
                state.activeServices.loading = true;
            })
            .addCase(fetchActiveServices.fulfilled, (state, action) => {
                state.activeServices.loading = false;
                state.activeServices.data = action.payload.data;
            });
    },
});

export const selectResidentialSummary = createSelector(
    [state => state.servicesState.summary],
    summary => summary.data || {},
);

export const selectResidentialSummaryLoading = createSelector(
    [state => state.servicesState.summary],
    summary => summary.loading,
);

export const selectUsersID = createSelector([selectResidentialSummary], data => data?.proxy_user_ids || []);

export const selectMetrics = createSelector([selectResidentialSummary, selectAnalytics], (summary, analytics) => {
    const { residential_bytes_used, residential_bytes_left, residential_bytes, proxy_user_ids } = summary;

    const formatBandwidth = bytes => {
        if (!bytes) {
            return '0 MB';
        }
        const GB = 1024 * 1024 * 1024;
        const TB = GB * 1024;
        if (bytes >= TB) {
            return `${(bytes / TB).toFixed(2)} TB`;
        } else if (bytes >= GB) {
            return `${(bytes / GB).toFixed(2)} GB`;
        } else {
            return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
        }
    };

    const used = formatBandwidth(Math.abs(residential_bytes_used));
    const overall = formatBandwidth(residential_bytes);
    const left = formatBandwidth(residential_bytes_left);
    const usage = `${used} / ${overall}`;
    const isNeedTopUp =
        residential_bytes_left / residential_bytes_used < 0.1 && residential_bytes_left > 0 && residential_bytes !== 0;

    let totalBytes = 0;
    let totalRequests = 0;

    analytics.forEach(i => {
        if (i.totals) {
            totalBytes += i.totals.totalBytes;
            totalRequests += i.totals.totalRequests;
        }
    });

    return [
        {
            title: 'usage',
            value: usage,
            used,
            isNoBandwidth: residential_bytes === 0 || residential_bytes_left === 0,
            isNeedTopUp,
        },
        {
            title: 'left',
            value: left,
            networks: [service_types.residential],
        },
        {
            title: 'total users',
            value: proxy_user_ids?.length,
            networks: [service_types.residential, ''],
        },
        {
            title: 'used range',
            value: formatBandwidth(totalBytes),
            networks: [service_types.residential, service_types.isp, service_types.datacenter, ''],
        },
        {
            title: 'requests range',
            value: totalRequests,
            networks: [service_types.residential, service_types.isp, service_types.datacenter, ''],
        },
    ];
});

export const selectServices = createSelector([state => state.servicesState.services], services => services.data || []);

export const selectServicesTableData = createSelector([selectServices], data => {
    return data.map(service => ({
        id: service.service_id,
        payment_method_id: service.payment_method_id,
        status: service.service_status,
        service: {
            id: service.service_id,
            country_id: service.country_id,
            type: service.service_type,
            name: service.service_name,
            value: service.service_quantity,
        },
        total: convertToDollar(service.service_total),
        auto_renewal: service.service_is_automatic_collection,
        due_date: service.service_expiry_datetime,
        type: service.service_type,
        service_price_id: service.service_price_id,
        service_quantity: service.service_quantity,
        service_cycle: service.service_cycle,
        subscription_schedule_id: service.subscription_schedule_id,
        open_invoice_id: service.open_invoice_id,
    }));
});

export const selectServicesLoading = createSelector(
    [state => state.servicesState.services],
    services => services.loading,
);

export const selectServicesTotal = createSelector([state => state.servicesState.services], services => services.total);

export const selectActiveServices = createSelector(
    [state => state.servicesState.activeServices],
    active => active.data,
);

export const selectActiveNonResidentialServices = createSelector([selectActiveServices], data =>
    data.filter(service => service.service_type !== service_types.residential),
);

export const selectServicesOptions = createSelector([selectActiveServices], data =>
    data?.map(service => ({
        value: service.service_id,
        label: service.service_name,
        Label: <Label text={`ID: ${service.service_id}`} color="grey" />,
    })),
);

export const selectNonResidentialServicesOptions = createSelector([selectActiveNonResidentialServices], data =>
    data?.map(service => ({
        value: service.service_id,
        label: `${service.service_name} (${service.service_id})`,
    })),
);

export default ServicesSlice.reducer;
