import PageHeader from 'components/PageHeader';
import { useState } from 'react';
import NoData from './NoData';
import ProxyUserSettingsTable from './Table';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchProxyUsers,
    selectProxyUsersLoading,
    selectProxyUsersTableData,
    selectProxyUsersTotal,
} from 'store/slices/proxyUsers';
import ModalCreateProxyUser from 'components/Modals/ModalCreateProxyUser';
import SearchInput from 'components/_common/SearchInput';
import FilterBar from 'components/FilterBar';
import InfoBanner from 'components/InfoBanner';

const ProxyUserSettings = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectProxyUsersTableData);
    const loading = useSelector(selectProxyUsersLoading);
    const total = useSelector(selectProxyUsersTotal);
    const [modalOpen, setModalOpen] = useState(false);
    const [search, setSearch] = useState('');

    const showCreateButton = !!data.length && !loading;

    return (
        <div>
            <PageHeader
                title="Proxy User Settings"
                right={showCreateButton && <ModalCreateProxyUser setOpen={setModalOpen} open={modalOpen} />}
            />
            <FilterBar left={<SearchInput value={search} onChange={setSearch} placeholder='Search by Proxy User ID' />} right={null} />
            <ProxyUserSettingsTable
                data={data}
                dataLoading={loading}
                // @ts-ignore
                fetchData={filters => dispatch(fetchProxyUsers(filters))}
                //
                staticFilters={{
                    proxy_user_is_deleted: false,
                }}
                filters={{
                    like_proxy_user_id: search ? `${search}%` : '',
                }}
                total={total}
                noDataComponent={<NoData setModalOpen={setModalOpen} modalOpen={modalOpen} />}
            />
            <p className='text-grey-500 mt-8'>{`Your Proxy User limit is ${data?.customer_proxy_user_limit || 5}. Please contact support if you would like to request an increase.`}</p>
            <InfoBanner
                {...{
                    marginBottom: 24,
                    marginTop: 24,
                    header: 'Get started with Proxy Authentication and Proxy Users',
                    content:
                        'Read our detailed guide which lays out all our authentication types and information needed to set up Proxy Users',
                    article: process.env.REACT_APP_API_DOCUMENTATION_PROXY_USERS_LINK,
                }}
            />
        </div>
    );
};

export default ProxyUserSettings;
