import AddUserButton from 'components/Buttons/AddUserButton';
import ConfirmButton from 'components/Buttons/ConfirmButton';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import MultipleInput from 'components/_common/MultipleInput';
import Autocomplete from 'components/_common/Autocomplete';
import TextInput from 'components/_common/TextInput';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { proxyUserValidationSchema } from 'helpers/validations';
import { selectNonResidentialServicesOptions } from 'store/slices/services';
import { fetchActiveServices } from 'store/slices/services';
import ProxyService from 'api/services/ProxyService';
import showMessage from 'components/NoticeProvider/showMessage';
import { fetchProxyUsers } from 'store/slices/proxyUsers';
import ServiceDataLine from 'components/ServiceDataLine';
import { service_types } from 'helpers/services';
import { convertBandwidth } from 'helpers';
import RadioGroupCards from 'components/_common/RadioGroupCards';
import { FormLabel, InputAdornment } from '@mui/material';
import { gb_max_real, gb_min } from 'pages/Product/constants';
import { GB } from 'helpers/constants';

const initialValues = { proxy_user_id: '', proxy_user_password: '' };

const ModalCreateProxyUser = ({ setOpen, open }) => {
    const dispatch = useDispatch();
    const non_residential_products_options = useSelector(selectNonResidentialServicesOptions);
    const [ips, setIps] = useState([]);
    const [products, setProducts] = useState([]);
    const [residentialServicePermission, setResidentialServicePermission] = useState('allow');
    const [residentialServiceLimitValue, setResidentialServiceLimitValue] = useState(0);
    const [otherServicesPermission, setOtherServicesPermission] = useState('allowAll');

    const isResidentialServiceAllowed = residentialServicePermission === 'allow';
    const isResidentialServiceLimited = residentialServiceLimitValue > 0;
    const isOtherServicesRestricted = otherServicesPermission === 'restrict';

    const optionsResidentialPermission = [
        {
            value: 'allow',
            label: 'Allow',
        },
        {
            value: 'disallow',
            label: 'Disallow',
        },
    ];

    const optionsOtherServicesPermission = [
        {
            value: 'allowAll',
            label: 'Allow All',
        },
        {
            value: 'restrict',
            label: 'Restrict',
        },
    ];

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchActiveServices());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIps([]);
        setProducts([]);
        formik.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const [loading, setLoading] = useState(false);

    const handleCreate = () => formik.handleSubmit();

    const handleChangeOtherServicesRestriction = value => {
        setOtherServicesPermission(value);
        if (value === 'allowAll') {
            setProducts([]);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: proxyUserValidationSchema,
        onSubmit: async params => {
            const data = {};
            if (params.proxy_user_id.length) data.proxy_user_id = params.proxy_user_id;
            if (params.proxy_user_password.length) data.proxy_user_password = params.proxy_user_password;
            if (ips.length) data.ip_address_authentications = ips;
            if (isResidentialServiceAllowed) {
                data.proxy_user_residential_bytes_limit = isResidentialServiceLimited
                    ? +convertBandwidth(residentialServiceLimitValue, 'B', GB)
                    : null;
            } else {
                data.proxy_user_residential_bytes_limit = 0;
            }
            if (products.length) data.restricted_service_ids = products;

            try {
                setLoading(true);
                await ProxyService.createUser(data)
                    .then(response => {
                        showMessage(response.data.message, response.status);
                    })
                    .then(() => {
                        setOpen(false);
                        // @ts-ignore
                        dispatch(fetchProxyUsers());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } catch (e) {
                return false;
            }
        },
    });

    return (
        <ControledDialog
            title="Create new proxy user"
            Trigger={<AddUserButton />}
            Submit={<ConfirmButton loading={loading} onClick={handleCreate} />}
            setOpen={setOpen}
            open={open}
            minHeight={540}
        >
            <h6>Authentication:</h6>
            <TextInput
                withFormik
                name="proxy_user_id"
                label="Proxy User ID"
                value={formik.values.proxy_user_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.proxy_user_id && Boolean(formik.errors.proxy_user_id)}
                helperText={formik.touched.proxy_user_id && formik.errors.proxy_user_id}
                placeholder="Leave empty for automatic generation"
            />
            <TextInput
                withFormik
                name="proxy_user_password"
                label="Password"
                value={formik.values.proxy_user_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.proxy_user_password && Boolean(formik.errors.proxy_user_password)}
                helperText={formik.touched.proxy_user_password && formik.errors.proxy_user_password}
                placeholder="Leave empty for automatic generation"
            />
            <MultipleInput values={ips} setValues={setIps} />
            <h6 className="mt-8">Authorization:</h6>
            <ServiceDataLine type={service_types.residential} name={'Residential Bandwidth'} />
            <div style={{ marginTop: '-35px' }}>
                <RadioGroupCards
                    horizontal
                    small
                    items={optionsResidentialPermission}
                    value={residentialServicePermission}
                    onChange={setResidentialServicePermission}
                />
                {isResidentialServiceAllowed && (
                    <div className="mt-8">
                        <TextInput
                            type="number"
                            min={gb_min}
                            max={gb_max_real}
                            value={residentialServiceLimitValue}
                            onChange={value => setResidentialServiceLimitValue(value)}
                            endAdornment={<InputAdornment position="end">GB</InputAdornment>}
                        />
                        <div className="service-line-title mt-8">Enter 0 for unlimited bandwidth</div>
                    </div>
                )}
            </div>
            <FormLabel>Static Proxies</FormLabel>
            <div style={{ marginTop: '-15px' }}>
                <RadioGroupCards
                    horizontal
                    small
                    items={optionsOtherServicesPermission}
                    value={otherServicesPermission}
                    onChange={handleChangeOtherServicesRestriction}
                />
            </div>
            {isOtherServicesRestricted && (
                <Autocomplete
                    fullWidth
                    withFormLabel
                    label="Link to product"
                    value={products}
                    options={non_residential_products_options}
                    onChange={setProducts}
                    multiple={true}
                />
            )}
        </ControledDialog>
    );
};

export default ModalCreateProxyUser;
