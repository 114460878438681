import * as React from 'react';
import { styled } from '@mui/material/styles';
import DialogComponent from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from 'icons/x-close.svg';
import CancelButton from 'components/Buttons/CancelButton';
import './styles.scss';

const BootstrapDialog = styled(DialogComponent)(({ theme }) => ({
    // '& .MuiDialogContent-root': {
    //     padding: theme.spacing(2),
    // },
    // '& .MuiDialogActions-root': {
    //     padding: theme.spacing(1),
    // },
}));

const DialogBody = ({
    Trigger,
    Submit,
    setOpen,
    open,
    title,
    children,
    withClose = true,
    onClose = undefined,
    withCancel = true,
    cancelText = undefined,
    minHeight = null,
    fullWidth,
}) => {
    const handleClose = () => {
        onClose && onClose();
        setOpen(false);
    };

    const handleOpen = () => setOpen(true);

    return (
        <React.Fragment>
            {Trigger && (
                <div className="custom-dialog-trigger-container" onClick={handleOpen}>
                    {Trigger}
                </div>
            )}
            <BootstrapDialog
                className={`custom-dialog ${fullWidth ? 'custom-dialog-fill-width' : ''}`}
                onClose={withClose ? handleClose : undefined}
                open={open}
            >
                <DialogTitle>{title}</DialogTitle>
                {withClose && (
                    <IconButton aria-label="close" onClick={handleClose} className="dialog-close-button">
                        <CloseIcon />
                    </IconButton>
                )}
                <DialogContent sx={{ minHeight: minHeight ? minHeight : 'auto' }}>{children}</DialogContent>
                {(withCancel || Submit) && (
                    <DialogActions>
                        {withCancel && <CancelButton onClick={handleClose} text={cancelText} />}
                        {Submit}
                    </DialogActions>
                )}
            </BootstrapDialog>
        </React.Fragment>
    );
};

export default DialogBody;
