import DashboardBandwidth from '../../DashboardBandwidth';
import MessageCard from 'components/MessageCard';
import { useSelector } from 'react-redux';
import { selectMetrics } from 'store/slices/services';
import ModalReconfigureService from 'components/Modals/ModalReconfigureService';
import { useState } from 'react';
import ServicesService from 'api/services/ServicesService';
import { selectActiveResidentialServiceId } from 'store/slices/customer';
import ModalUnpauseService from 'components/Modals/ModalPauseUnpauseService';

const ResidentalProxiesTab = () => {
    const metrics = useSelector(selectMetrics);
    const active_residential_service_id = useSelector(selectActiveResidentialServiceId);

    const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);
    const [isUnpauseModalOpen, setIsUnpauseModalOpen] = useState(false);
    const [topUpData, setTopUpData] = useState(null);
    const [isTopUpLoading, setIsTopUpLoading] = useState(false);

    const continueTopUp = async () => {
        try {
            setIsTopUpLoading(true);
            await ServicesService.reconfigureCalculate({ service_id: active_residential_service_id })
                .then(response => {
                    setTopUpData({ ...response.data, service_id: active_residential_service_id });
                })
                .finally(() => {
                    setIsTopUpLoading(false);
                });
        } catch (error) {
            return false;
        }
    };

    const handleTopUp = async () => {
        try {
            setIsTopUpLoading(true);
            const response = await ServicesService.retrieve(active_residential_service_id);
            setIsTopUpLoading(false);
            const isPaused = response?.data?.data?.[0]?.service_subscription_is_paused;
            if (isPaused) {
                setIsUnpauseModalOpen(true);
            } else {
                setIsTopUpModalOpen(true);
                await continueTopUp();
            }
        } catch (error) {
            return false;
        }
    };

    return (
        <>
            {metrics?.find(metric => metric.title === 'usage')?.isNeedTopUp ? (
                <div className="mb-24">
                    <MessageCard
                        buttonText="Top Up"
                        customStyles={{ display: 'flex', gap: 5, height: 100 }}
                        customButtonStyles={{ display: 'block', width: 200, marginLeft: 'auto' }}
                        type="bad"
                        message="You are close to running out of bandwidth, top up now to continue using Residential proxies."
                        isLoading={isTopUpLoading}
                        onClick={handleTopUp}
                    />
                </div>
            ) : null}
            <DashboardBandwidth />
            <ModalReconfigureService
                setOpen={setIsTopUpModalOpen}
                open={isTopUpModalOpen}
                existingSecondData={topUpData}
                isExistingLoading={isTopUpLoading}
                isResidential={true}
                title="Top Up Now"
                successMessage="Top Up is complete"
            />
            <ModalUnpauseService
                open={isUnpauseModalOpen}
                setOpen={setIsUnpauseModalOpen}
                customContent={<p>Service is paused. Please unpause the service to reconfigure.</p>}
                service_id={active_residential_service_id}
                onClick={continueTopUp}
            />
        </>
    );
};

export default ResidentalProxiesTab;
