import { Link } from 'react-router-dom';
import './styles.scss';
import { ReactComponent as ExternalIcon } from 'icons/external-link.svg';
import { ReactComponent as ChevronIcon } from 'icons/chevron-right.svg';

const CustomLink = ({ children, to = null, onClick = null, blank = false, external = false, withArrow = false }) => {
    const blankOptions = blank && !onClick
        ? {
              target: '_blank',
              rel: 'noopener noreferrer',
          }
        : {};

    return (
        <Link to={to} onClick={onClick} className="custom-link" {...blankOptions}>
            {children}
            {external && <ExternalIcon className="custom-link__icon" />}
            {withArrow && <ChevronIcon className="custom-link__icon" />}
        </Link>
    );
};

export default CustomLink;
