import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from 'components/_common/Table';
import CopyCell from 'components/_common/TableCells/CopyCell';
import ListCell from 'components/_common/TableCells/ListCell';
import CopyTextCell from 'components/_common/TableCells/CopyTextCell';
import Flag from 'components/_common/CountryDropdown/Items/Flag';

import { selectSelectedIDs, setIDs } from 'store/slices/proxy';
import { getServiceIcon } from 'helpers/services';

const minWidth = 140;

const ProxyListTable = ({ data, dataLoading, fetchData, total, noDataComponent, filters }) => {
    const ids = useSelector(selectSelectedIDs);
    const dispatch = useDispatch();

    const columns = React.useMemo(
        () => [
            {
                field: 'id',
                headerName: 'Proxy ID',
                flex: 1,
                minWidth,
                renderCell: ({ value, row }) => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <div style={{ width: 25, height: 25 }}>
                            {getServiceIcon(row.type) ? getServiceIcon(row.type) : row.type}
                        </div>
                        <CopyTextCell text={value} />
                    </div>
                ),
            },
            {
                field: 'service_id',
                headerName: 'Service ID',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => <CopyTextCell text={value} />,
            },
            {
                field: 'ip_address',
                headerName: 'IP Address',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => <CopyTextCell text={value} />,
            },
            {
                field: 'proxy_http_port',
                headerName: 'HTTP Port',
                minWidth,
                renderCell: ({ value }) => <CopyTextCell text={value} />,
            },
            {
                field: 'proxy_socks5_port',
                headerName: 'SOCKS Port',
                minWidth,
                renderCell: ({ value }) => <CopyTextCell text={value} />,
            },
            {
                field: 'proxy_username',
                headerName: 'Proxy Username',
                minWidth: 190,
                renderCell: ({ value }) => <CopyTextCell text={value} />,
            },
            {
                field: 'proxy_password',
                headerName: 'Proxy Password',
                minWidth: 190,
                renderCell: ({ value }) => <CopyTextCell text={value} />,
            },
            {
                field: 'users',
                headerName: 'Users',
                flex: 1,
                minWidth: 180,
                renderCell: ({ value }) => (
                    <ListCell data={value ? [...value].sort((a, b) => a.localeCompare(b)) : []} max_size={1} />
                ),
            },
            {
                field: 'country',
                headerName: 'Country',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => (
                    <CopyTextCell
                        text={
                            <span style={{ display: 'flex' }}>
                                <Flag country_id={value.id} />
                            </span>
                        }
                        hoverText={value?.name}
                    />
                ),
            },
            {
                field: 'carrier',
                headerName: 'Carrier',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => <CopyTextCell text={value?.id} hoverText={value?.name} />,
            },
            {
                field: 'copy',
                headerName: '',
                flex: 1,
                minWidth: 90,
                renderCell: ({ value }) => <CopyCell value={value} />,
            },
        ],
        [],
    );

    const handleSelect = value => dispatch(setIDs(value));

    return (
        <Table
            withSelection
            onRowSelectionModelChange={handleSelect}
            rowSelectionModel={ids}
            columns={columns}
            rows={data}
            dataLoading={dataLoading}
            fetchData={fetchData}
            filters={filters}
            total={total}
            noDataComponent={noDataComponent}
        />
    );
};

export default ProxyListTable;
