import CheckoutService from 'api/services/CheckoutService';
import AllowedPaymentPlatforms from 'components/AllowedPaymentPlatforms';
import ConfirmButton from 'components/Buttons/ConfirmButton';
import Button from 'components/_common/Button';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import TextInput from 'components/_common/TextInput';
import { withoutEmptyValues } from 'helpers';
import { omit } from 'lodash';
import { useEffect, useState } from 'react';
import { VISIBLE_PAYMENT_PLATFORMS } from 'helpers/constants';
import Label from 'components/_common/Label';
import ModalSuccesfullyPaidInvoice from './ModalSuccesfullyPaidInvoice';

const initialValue = 100;

const ModalTopUp = ({ smallButton = false, labelButton = false, labelText = 'Top-up', labelColor = 'green' }) => {
    const [open, setOpen] = useState(false);
    const [isSuccessfullyPaidModalOpen, setIsSuccessfullyPaidModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(initialValue);
    const [allowed_payment_platforms, setAllowedPaymentPlatforms] = useState([]);
    const [platform, setPlatform] = useState(null);
    const [succesfullyPaidModalRedirectUrl, setSuccesfullyPaidModalRedirectUrl] = useState(null);

    useEffect(() => {
        setValue(initialValue);
        setAllowedPaymentPlatforms([]);
    }, [open]);

    const params = withoutEmptyValues({
        is_top_up: true,
        top_up_amount: value * 100,
        validate_payment_platform: true,
        payment_platform: platform,
    });

    const handleNext = async () => {
        try {
            setLoading(true);
            await CheckoutService.calculate(params)
                .then(response => {
                    setAllowedPaymentPlatforms(
                        response.data.data.allowed_payment_platforms.filter(i => VISIBLE_PAYMENT_PLATFORMS.includes(i)),
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            await CheckoutService.create(omit(params, ['validate_payment_platform']))
                .then(response => {
                    const redirect_url = response?.data?.data?.redirect_url;
                    if (response.data?.data?.invoice_is_paid) {
                        setSuccesfullyPaidModalRedirectUrl(redirect_url);
                        setIsSuccessfullyPaidModalOpen(true);
                    } else {
                        window?.open(redirect_url, '_self');
                    }
                    setOpen(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    const disableNext = !value || value <= 0 || loading;

    const button = !allowed_payment_platforms.length ? (
        <Button disabled={disableNext} onClick={handleNext} loading={loading}>
            Next
        </Button>
    ) : (
        <ConfirmButton onClick={handleSubmit} loading={loading} />
    );

    const hasPlatforms = !!allowed_payment_platforms.length;

    return (
        <>
            {labelButton ? (
                <Label color={labelColor} text={labelText} onClick={() => setOpen(true)} />
            ) : (
                <Button
                    iconName={smallButton ? null : 'plus'}
                    onClick={() => setOpen(true)}
                    size={smallButton ? 'small' : 'medium'}
                >
                    Top-up
                </Button>
            )}
            <ControledDialog title="Top-up" Submit={button} setOpen={setOpen} open={open}>
                <TextInput
                    type="number"
                    disabled={hasPlatforms}
                    label="Enter amount $"
                    value={value}
                    onChange={setValue}
                />
                {hasPlatforms && (
                    <AllowedPaymentPlatforms
                        allowed_payment_platforms={allowed_payment_platforms}
                        value={platform}
                        onChange={setPlatform}
                    />
                )}
            </ControledDialog>
            <ModalSuccesfullyPaidInvoice
                open={isSuccessfullyPaidModalOpen}
                setOpen={setIsSuccessfullyPaidModalOpen}
                redirectUrl={succesfullyPaidModalRedirectUrl}
            />
        </>
    );
};

export default ModalTopUp;
