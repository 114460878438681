import PageHeader from 'components/PageHeader';
import { useEffect, useState } from 'react';
import NoData from './NoData';
import ProxyListTable from './Table';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectProxyListLoading,
    fetchProxyList,
    selectProxyListTableData,
    selectProxyListTotal,
    fetchProxyFilterOptions,
} from 'store/slices/proxy';
import ProxyTableBar from './ProxyTableBar';
import ProxyListFilters from './ProxyListFilters';
import { useLocation } from 'react-router-dom';
import { fetchServicesSummary } from 'store/slices/services';

import InfoBanner from 'components/InfoBanner';

const ProxyList = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const data = useSelector(selectProxyListTableData);
    const loading = useSelector(selectProxyListLoading);
    const total = useSelector(selectProxyListTotal);

    const [service, setService] = useState('');
    const [type, setType] = useState('');
    const [country, setCountry] = useState('');
    const [search, setSearch] = useState('');

    const service_id = new URLSearchParams(location.search).get('service_id');

    useEffect(() => {
        if (service_id) {
            setService(service_id);
        }
        // @ts-ignore
        dispatch(fetchServicesSummary({}));
        //
        // @ts-ignore
        dispatch(fetchProxyFilterOptions());
        //
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const props = { type, setType, service, setService, country, setCountry, search, setSearch };

    return (
        <div>
            <PageHeader title="Static Proxy List" />
            <ProxyTableBar filters={<ProxyListFilters {...props} />} />
            <ProxyListTable
                data={data}
                dataLoading={loading}
                // @ts-ignore
                fetchData={filters => dispatch(fetchProxyList(filters))}
                //
                filters={{
                    service_id: service,
                    country_id: country,
                    proxy_type: type,
                    like_ip_address_id_v4: search ? `${search}%` : '',
                }}
                total={total}
                noDataComponent={<NoData />}
            />
            <InfoBanner
                {...{
                    marginBottom: 24,
                    marginTop: 24,
                    header: 'Need help understanding Static Lists and Exporting?',
                    content:
                        'Read our detailed guide on proxy lists and exporting.',
                    article: process.env.REACT_APP_API_DOCUMENTATION_PROXY_LIST_LINK,
                }}
            />
        </div>
    );
};

export default ProxyList;
