import { Outlet } from 'react-router-dom';
import { ScrollRestoration } from 'react-router-dom';
import FeaturebaseWidget from 'widgets/FeaturebaseWidget';
import MavaWidget from 'widgets/MavaWidget';

const Layout = () => (
    <>
        <ScrollRestoration />
        <Outlet />
        <MavaWidget />
        <FeaturebaseWidget />
    </>
);

export default Layout;
