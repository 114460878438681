import { ReactComponent as Icon1 } from 'icons/service-logo-3.svg';
import { ReactComponent as Icon2 } from 'icons/service-logo-1.svg';
import { ReactComponent as Icon3 } from 'icons/service-logo-2.svg';
import { ReactComponent as GeneralPingIcon } from 'icons/general-ping-icon.svg';
import { GB, IPs } from './constants';
import { uppercaseFirst } from 'helpers';
import { payment_platform_types } from './payments';
import { omit } from 'lodash';

export const service_types = {
    datacenter: 'datacenter',
    isp: 'isp',
    residential: 'residential',
};

export const service_statuses = [
    { value: '', label: 'All' },
    { value: 'awaiting_fulfillment', label: 'Awaiting Fulfillment' },
    { value: 'awaiting_manual_fulfillment', label: 'Awaiting Manual Fulfillment' },
    { value: 'awaiting_additional_fulfillment', label: 'Awaiting Additional Fulfillment' },
    { value: 'active', label: 'Active' },
    { value: 'canceled', label: 'Canceled' },
    { value: 'complete', label: 'Complete' },
    { value: 'overdue', label: 'Overdue' },
];

export const getServiceIcon = type => {
    const icons = {
        [service_types.datacenter]: <Icon1 />,
        [service_types.isp]: <Icon2 />,
        [service_types.residential]: <Icon3 />,
    };

    return icons[type];
};

export const getUnitByType = type => {
    const data = {
        [service_types.datacenter]: IPs,
        [service_types.isp]: IPs,
        [service_types.residential]: GB,
    };
    return data[type];
};

export const services_type_options = [
    { value: service_types.isp, label: uppercaseFirst(service_types.isp), icon: () => <Icon2 /> },
    { value: service_types.datacenter, label: uppercaseFirst(service_types.datacenter), icon: () => <Icon1 /> },
    { value: service_types.residential, label: uppercaseFirst(service_types.residential), icon: () => <Icon3 /> },
];

export const networksSelect = [
    { value: '', label: 'All', icon: () => <GeneralPingIcon /> },
    { value: service_types.residential, label: 'Residential', icon: () => <Icon3 /> },
    { value: service_types.isp, label: 'Static ISP', icon: () => <Icon2 /> },
    { value: service_types.datacenter, label: 'Datacenter', icon: () => <Icon1 /> },
];

export const filterPaymentParamsByPlatform = (platform, params) => {
    switch (platform) {
        case payment_platform_types.stripe:
            return omit(params, ['coinpayments_currency']);
        case payment_platform_types.coinpayments:
            return omit(params, ['payment_method_id']);
        case payment_platform_types.bitpay:
            return omit(params, ['coinpayments_currency', 'payment_method_id']);
        case payment_platform_types.coingate:
            return omit(params, ['coinpayments_currency', 'payment_method_id']);
        default:
            return params;
    }
};

export const serviceStatuses = {
    AWAITING_FULFILLMENT: 'awaiting_fulfillment',
    AWAITING_MANUAL_FULFILLMENT: 'awaiting_manual_fulfillment',
    AWAITING_ADDITIONAL_FULFILLMENT: 'awaiting_additional_fulfillment',
    ACTIVE: 'active',
    CANCELED: 'canceled',
    COMPLETE: 'complete',
    PAUSED: 'paused',
    OVERDUE: 'overdue'
};
