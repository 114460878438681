import Table from 'components/_common/Table';
import Label from 'components/_common/Label';
import PaymentCellByID from 'components/_common/TableCells/PaymentCellByID';
import ServiceCell from 'components/_common/TableCells/ServiceCell';
import StatusCell from 'components/_common/TableCells/StatusCell';
import ScheduleStatusCell from 'components/_common/TableCells/ScheduleStatusCell';
import TextCell from 'components/_common/TableCells/TextCell';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { ReactComponent as Icon2 } from 'icons/credit-card.svg';
import { ReactComponent as EditIcon } from 'icons/edit.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as PauseIcon } from 'icons/pause.svg';
import React, { useState } from 'react';
import ModalEditBilling from 'components/Modals/ModalEditBilling';
import { getParams } from 'components/GenerateViewProxies';
import { useNavigate } from 'react-router-dom';
import ModalReconfigureService from 'components/Modals/ModalReconfigureService';
import ServicesService from 'api/services/ServicesService';
import ModalPauseUnpauseService from 'components/Modals/ModalPauseUnpauseService';
import ModalCancelUpcomingReconfiguration from 'components/Modals/ModalCancelUpcomingReconfiguration';
import ModalPay from 'components/Modals/ModalPay';
import QuickView from 'components/Modals/QuickView';
import { withoutEmptyValues } from 'helpers';
import { service_types, serviceStatuses } from 'helpers/services';

const SummaryTable = ({ data, dataLoading, fetchData, staticFilters, total, noDataComponent, filters }) => {
    const navigate = useNavigate();
    const [editOpen, setEditOpen] = useState(false);
    const [serviceData, setServiceData] = useState(null);
    const [reconfigureOpen, setReconfigureOpen] = useState(false);
    const [topUpOpen, setTopUpOpen] = useState(false);
    const [isTopUpLoading, setIsTopUpLoading] = useState(false);
    const [pauseUnpauseOpen, setPauseUnpauseOpen] = useState(false);
    const [cancelUpcomingReconfigurationOpen, setCancelUpcomingReconfigurationOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [isQuickViewModalOpen, setIsQuickViewModalOpen] = useState(false);

    const [isPayModalOpen, setIsPayModalOpen] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');

    const handlePay = invoiceId => {
        if (invoiceId) {
            setInvoiceId(invoiceId);
            setIsPayModalOpen(true);
        }
    };

    const handleQuickView = row => {
        setSelectedService(row);
        setIsQuickViewModalOpen(true);
    };

    const columns = React.useMemo(
        () => [
            {
                field: 'status',
                headerName: 'status',
                minWidth: 250,
                flex: 1,
                renderCell: ({ value, row }) => (
                    <div style={{ display: 'flex', gap: 5, flexDirection: 'column', alignItems: 'flex-start' }}>
                        <StatusCell status={value} />
                        <ScheduleStatusCell id={row.subscription_schedule_id} />
                    </div>
                ),
            },
            {
                field: 'service',
                headerName: 'service',
                minWidth: 350,
                flex: 1,
                renderCell: ({ value }) => <ServiceCell withFlag data={value} />,
            },
            {
                field: 'total',
                headerName: 'total',
                minWidth: 120,
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'due_date',
                headerName: 'Due Date',
                minWidth: 200,
                flex: 1,
                renderCell: ({ value, row }) => {
                    if (
                        row?.status === serviceStatuses.CANCELED ||
                        row?.status === serviceStatuses.COMPLETE ||
                        row?.status === serviceStatuses.OVERDUE
                    ) {
                        return null;
                    } else {
                        return <TextCell text={value} />;
                    }
                },
            },
            {
                field: 'payment_method_id',
                headerName: 'Payment Method',
                flex: 1,
                minWidth: 150,
                renderCell: ({ value, row }) =>
                    row?.auto_renewal ? <PaymentCellByID id={value} /> : <Label text={'Manual Payment'} />,
            },
            {
                field: 'open_invoice_id',
                headerName: 'Actions',
                flex: 1,
                minWidth: 150,
                renderCell: ({ row }) => (
                    <div className="invoices-table-buttons-wrapper">
                        {row.open_invoice_id && (
                            <div className="pointer">
                                <Label color={'yellow'} text={'Pay'} onClick={() => handlePay(row.open_invoice_id)} />
                            </div>
                        )}
                        {row.status === serviceStatuses.ACTIVE &&
                            [service_types.datacenter, service_types.isp].includes(row.service.type) && (
                                <div className="pointer">
                                    <Label color={'green'} text={'Quick View'} onClick={() => handleQuickView(row)} />
                                </div>
                            )}
                    </div>
                ),
            },
            {
                field: 'actions',
                type: 'actions',
                getActions: params => {
                    const isResidential = params.row.type === service_types.residential;
                    switch (params.row.status) {
                        case 'paused':
                            return [
                                <GridActionsCellItem
                                    icon={<PauseIcon />}
                                    showInMenu
                                    label="Unpause Subscription"
                                    onClick={() => handlePauseUnpauseSubscriptionOpen(params)}
                                />,
                            ];
                        case 'active':
                            const actionSettings = [
                                <GridActionsCellItem {...getParams(params, navigate)} />,
                                params.row.subscription_schedule_id ? (
                                    <GridActionsCellItem
                                        icon={<EditIcon />}
                                        showInMenu
                                        label="Cancel Upcoming Reconfiguration"
                                        onClick={() => handleCancelUpcomingReconfigurationOpen(params)}
                                    />
                                ) : (
                                    <GridActionsCellItem
                                        icon={<EditIcon />}
                                        showInMenu
                                        label="Reconfigure Service"
                                        onClick={() => handleReconfigureOpen(params)}
                                    />
                                ),
                                <GridActionsCellItem
                                    icon={<Icon2 />}
                                    showInMenu
                                    label="Edit billing"
                                    onClick={() => handleEditOpen(params)}
                                />,
                            ];
                            if (isResidential) {
                                actionSettings.push(
                                    <GridActionsCellItem
                                        icon={<PlusIcon />}
                                        showInMenu
                                        label="Top Up Now"
                                        onClick={() => handleTopUpOpen(params)}
                                    />,
                                );

                                actionSettings.push(
                                    <GridActionsCellItem
                                        icon={<PauseIcon />}
                                        showInMenu
                                        label="Pause Subscription"
                                        onClick={() => handlePauseUnpauseSubscriptionOpen(params)}
                                    />,
                                );
                            }

                            return actionSettings;
                        default:
                            return [];
                    }
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleEditOpen = params => {
        setServiceData(params.row);
        setEditOpen(true);
    };

    const handleReconfigureOpen = params => {
        setServiceData(params.row);
        setReconfigureOpen(true);
    };

    const handleTopUpOpen = async params => {
        try {
            setIsTopUpLoading(true);
            setTopUpOpen(true);
            await ServicesService.reconfigureCalculate({ service_id: params.id })
                .then(response => {
                    setServiceData({ ...response.data, service_id: params.id });
                })
                .finally(() => {
                    setIsTopUpLoading(false);
                });
        } catch (error) {
            return false;
        }
    };

    const handlePauseUnpauseSubscriptionOpen = params => {
        setServiceData(params.row);
        setPauseUnpauseOpen(true);
    };

    const handleCancelUpcomingReconfigurationOpen = params => {
        setServiceData(params.row);
        setCancelUpcomingReconfigurationOpen(true);
    };

    return (
        <>
            <Table
                columns={columns}
                rows={data}
                dataLoading={dataLoading}
                fetchData={fetchData}
                staticFilters={staticFilters}
                filters={filters}
                total={total}
                noDataComponent={noDataComponent}
                rowHeight={60}
            />
            <ModalEditBilling setOpen={setEditOpen} open={editOpen} data={serviceData} type="services" />
            {reconfigureOpen && (
                <ModalReconfigureService
                    setOpen={setReconfigureOpen}
                    open={reconfigureOpen}
                    data={serviceData}
                    existingSecondData={null}
                    isResidential={serviceData.type === service_types.residential}
                />
            )}
            <ModalReconfigureService
                setOpen={setTopUpOpen}
                open={topUpOpen}
                existingSecondData={serviceData}
                isExistingLoading={isTopUpLoading}
                isResidential={true}
                title="Top Up Now"
                successMessage="Top Up is complete"
            />
            <ModalPauseUnpauseService
                setOpen={setPauseUnpauseOpen}
                open={pauseUnpauseOpen}
                service_id={serviceData?.id}
                onClick={() => fetchData(withoutEmptyValues({ ...filters, ...staticFilters, page: 1, per_page: 10 }))}
                isPause={serviceData?.status === 'active'}
            />
            <ModalCancelUpcomingReconfiguration
                setOpen={setCancelUpcomingReconfigurationOpen}
                open={cancelUpcomingReconfigurationOpen}
                subscription_schedule_id={serviceData?.subscription_schedule_id}
                onClick={() => fetchData(withoutEmptyValues({ ...filters, ...staticFilters, page: 1, per_page: 10 }))}
            />
            {isPayModalOpen && (
                <ModalPay isPayModalOpen={isPayModalOpen} setIsPayModalOpen={setIsPayModalOpen} invoiceId={invoiceId} />
            )}
            {isQuickViewModalOpen && (
                <QuickView
                    isQuickViewModalOpen={isQuickViewModalOpen}
                    setIsQuickViewModalOpen={setIsQuickViewModalOpen}
                    service={selectedService}
                />
            )}
        </>
    );
};

export default SummaryTable;
