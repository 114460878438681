import ServicesService from 'api/services/ServicesService';
import AddNewMethodButton from 'components/Buttons/AddNewMethodButton';
import showMessage from 'components/NoticeProvider/showMessage';
import Button from 'components/_common/Button';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import RadioGroupCards from 'components/_common/RadioGroupCards';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchPaymentMethodsForPopup,
    fetchBillingOrders,
    selectPaymentMethodsOptions,
    selectPaymentMethodsLoading,
} from 'store/slices/billingOrders';
import { fetchServicesSummary } from 'store/slices/services';

const ModalEditBilling = ({ setOpen, open, data, type }) => {
    const dispatch = useDispatch();
    const { auto_renewal, id, payment_method_id } = data || {};
    const options = useSelector(selectPaymentMethodsOptions);
    const methodsLoading = useSelector(selectPaymentMethodsLoading);
    const [paymentMethodId, setPaymentMethodId] = useState('');
    const [loading, setLoading] = useState(false);
    const [autoRenewal, setAutoRenewal] = useState(auto_renewal);

    // @ts-ignore
    const getMethods = () => dispatch(fetchPaymentMethodsForPopup());

    useEffect(() => {
        getMethods();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAutoRenewal(auto_renewal);
        if (open) getMethods();
        if (data) setPaymentMethodId(options?.find(option => option.value === payment_method_id)?.value || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, open]);

    useEffect(() => {
        if (!autoRenewal) {
            setPaymentMethodId(null);
        }
    }, [autoRenewal]);

    const paymentChanged = paymentMethodId !== payment_method_id;
    const autoRenewalChanged = autoRenewal !== auto_renewal;
    const optionsAutoRenewal = [
        {
            value: true,
            label: 'Yes',
            disabled: !options.length
        },
        {
            value: false,
            label: 'No',
        },
    ];

    const handleSetAutoRenewal = value => {
        setAutoRenewal(value === 'true');
    };

    const handleSubmit = async () => {
        const params = { service_id: id };
        if (paymentChanged) params.payment_method_id = paymentMethodId;
        if (autoRenewalChanged) params.service_is_automatic_collection = autoRenewal;
        if (!autoRenewal) params.payment_method_id = null;

        try {
            setLoading(true);
            await ServicesService.edit(params)
                .then(response => {
                    showMessage(response.data.message, response.status);
                })
                .then(() => {
                    setOpen(false);
                    if (type === 'services') {
                        // @ts-ignore
                        dispatch(fetchServicesSummary());
                    }
                    if (type === 'orders') {
                        // @ts-ignore
                        dispatch(fetchBillingOrders());
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    const disabled = !paymentChanged && !autoRenewalChanged || (autoRenewal && !paymentMethodId);

    const SubmitButton = () => (
        <Button disabled={disabled} loading={loading} onClick={handleSubmit}>
            Confirm and change
        </Button>
    );

    return (
        !methodsLoading && (
            <ControledDialog title="Edit billing" Submit={<SubmitButton />} setOpen={setOpen} open={open}>
                <RadioGroupCards
                    horizontal
                    small
                    label="Automatic Renewal"
                    items={optionsAutoRenewal}
                    value={autoRenewal}
                    onChange={handleSetAutoRenewal}
                />
                <RadioGroupCards
                    label="Select payment card"
                    items={options}
                    value={paymentMethodId}
                    onChange={setPaymentMethodId}
                    disabled={!autoRenewal}
                />
                <div onClick={() => setOpen(false)} className="self-start">
                    <AddNewMethodButton variant="text" className="self-start" />
                </div>
            </ControledDialog>
        )
    );
};

export default ModalEditBilling;
