import ConfirmButton from 'components/Buttons/ConfirmButton';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import Autocomplete from 'components/_common/Autocomplete';
import TextInput from 'components/_common/TextInput';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { proxyEditUserValidationSchema } from 'helpers/validations';
import { fetchProxyUsers } from 'store/slices/proxyUsers';
import { useDispatch, useSelector } from 'react-redux';
import showMessage from 'components/NoticeProvider/showMessage';
import ProxyService from 'api/services/ProxyService';
import { fetchActiveServices, selectNonResidentialServicesOptions } from 'store/slices/services';
import { isEqual } from 'lodash';
import ServiceDataLine from 'components/ServiceDataLine';
import { service_types } from 'helpers/services';
import { convertBandwidth } from 'helpers';
import RadioGroupCards from 'components/_common/RadioGroupCards';
import MultipleInput from 'components/_common/MultipleInput';
import { FormLabel, InputAdornment } from '@mui/material';
import { gb_max_real, gb_min } from 'pages/Product/constants';
import { GB } from 'helpers/constants';

const ModalEditProxyUser = ({ setOpen, open, data }) => {
    const dispatch = useDispatch();
    const non_residential_products_options = useSelector(selectNonResidentialServicesOptions);
    const [loading, setLoading] = useState(false);
    const [ips, setIps] = useState([]);
    const [products, setProducts] = useState([]);
    const [residentialServicePermission, setResidentialServicePermission] = useState('allow');
    const [initialResidentialServicePermission, setInitialResidentialServicePermission] = useState('allow');
    const [residentialServiceLimitValue, setResidentialServiceLimitValue] = useState(0);
    const [otherServicesPermission, setOtherServicesPermission] = useState('allowAll');

    const isResidentialServiceAllowed = residentialServicePermission === 'allow';
    const isResidentialServiceLimited = residentialServiceLimitValue > 0;
    const isOtherServicesRestricted = otherServicesPermission === 'restrict';

    const optionsResidentialPermission = [
        {
            value: 'allow',
            label: 'Allow',
        },
        {
            value: 'disallow',
            label: 'Disallow',
        },
    ];

    const optionsOtherServicesPermission = [
        {
            value: 'allowAll',
            label: 'Allow All',
        },
        {
            value: 'restrict',
            label: 'Restrict',
        },
    ];

    const { whitelisted_ip, linked_products, id, password, name, residential_bandwidth_usage } = data || {};

    const initialValues = {
        id: id,
        proxy_user_password: password,
        products: linked_products,
        limit: residential_bandwidth_usage,
    };

    const handleChangeOtherServicesRestriction = value => {
        setOtherServicesPermission(value);
        if (value === 'allowAll') {
            setProducts([]);
        }
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: proxyEditUserValidationSchema,
        onSubmit: async params => {
            const newParams = { id: params.id };
            if (params.proxy_user_password !== data?.password)
                newParams.proxy_user_password = params.proxy_user_password;
            if (!isEqual(ips, whitelisted_ip)) newParams.ip_address_authentications = ips;
            if (isResidentialServiceAllowed) {
                newParams.proxy_user_residential_bytes_limit = isResidentialServiceLimited
                    ? +convertBandwidth(residentialServiceLimitValue, 'B', GB)
                    : null;
            } else {
                newParams.proxy_user_residential_bytes_limit = 0;
            }
            if (!isEqual(products, linked_products)) newParams.restricted_service_ids = products;

            try {
                setLoading(true);
                await ProxyService.editUser(newParams)
                    .then(response => {
                        showMessage(response.data.message, response.status);
                        setLoading(false);
                    })
                    .then(() => {
                        setOpen(false);
                        // @ts-ignore
                        dispatch(fetchProxyUsers());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } catch (e) {
                return false;
            }
        },
    });

    useEffect(() => {
        if (open && data) {
            setIps(whitelisted_ip);
            if (linked_products.length) {
                setProducts(linked_products);
                setOtherServicesPermission('restrict');
            }
            const residentialPermission = residential_bandwidth_usage?.limit === 0 ? 'disallow' : 'allow';
            setResidentialServicePermission(residentialPermission);
            setInitialResidentialServicePermission(residentialPermission);
            setResidentialServiceLimitValue(+convertBandwidth(residential_bandwidth_usage?.limit));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchActiveServices());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = () => formik.handleSubmit();

    const disabled =
        formik.values.proxy_user_password === password &&
        isEqual(ips, whitelisted_ip) &&
        isEqual(products, linked_products) &&
        residential_bandwidth_usage?.limit === residentialServiceLimitValue &&
        initialResidentialServicePermission === residentialServicePermission;

    return (
        <ControledDialog
            title="Edit proxy user"
            Submit={<ConfirmButton onClick={handleSubmit} disabled={disabled} loading={loading} />}
            setOpen={setOpen}
            open={open}
            minHeight={540}
        >
            <h6>Authentication:</h6>
            <TextInput disabled label="Proxy User ID" value={name} onChange={undefined} />
            <TextInput
                withFormik
                name="proxy_user_password"
                label="Password"
                value={formik.values.proxy_user_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.proxy_user_password && Boolean(formik.errors.proxy_user_password)}
                helperText={formik.touched.proxy_user_password && formik.errors.proxy_user_password}
            />
            <MultipleInput values={ips} setValues={setIps} />
            <h6>Authorization:</h6>
            <ServiceDataLine type={service_types.residential} name={'Residential Bandwidth'} />
            <div style={{ marginTop: '-35px' }}>
                <RadioGroupCards
                    horizontal
                    small
                    items={optionsResidentialPermission}
                    value={residentialServicePermission}
                    onChange={setResidentialServicePermission}
                />
                {isResidentialServiceAllowed && (
                    <div className="mt-8">
                        <TextInput
                            type="number"
                            min={gb_min}
                            max={gb_max_real}
                            value={residentialServiceLimitValue}
                            onChange={value => setResidentialServiceLimitValue(value)}
                            endAdornment={<InputAdornment position="end">GB</InputAdornment>}
                        />
                        <div className="service-line-title mt-8">Enter 0 for unlimited bandwidth</div>
                    </div>
                )}
            </div>
            <FormLabel>Static Proxies</FormLabel>
            <div style={{ marginTop: '-15px' }}>
                <RadioGroupCards
                    horizontal
                    small
                    items={optionsOtherServicesPermission}
                    value={otherServicesPermission}
                    onChange={handleChangeOtherServicesRestriction}
                />
            </div>
            {isOtherServicesRestricted && (
                <Autocomplete
                    fullWidth
                    withFormLabel
                    label="Link to product"
                    value={products}
                    options={non_residential_products_options}
                    onChange={setProducts}
                    multiple={true}
                />
            )}
        </ControledDialog>
    );
};

export default ModalEditProxyUser;
