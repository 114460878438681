import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCustomer } from 'store/slices/customer';

const FeaturebaseWidget = () => {
    const { customer_id } = useSelector(selectCustomer);
    const [isWidgetInitialized, setWidgetInitialized] = useState(false);

    useEffect(() => {
        const isScriptExist = document.getElementById('featurebase-sdk');

        if (!isScriptExist) {
            const featurebaseSDKScript = document.createElement('script');
            featurebaseSDKScript.id = 'featurebase-sdk';
            featurebaseSDKScript.src = 'https://do.featurebase.app/js/sdk.js';
            featurebaseSDKScript.async = true;

            featurebaseSDKScript.onload = () => {
                // Initialize the Featurebase function
                // @ts-ignore
                window.Featurebase =
                    window.Featurebase ||
                    function () {
                        // @ts-ignore
                        (window.Featurebase.q = window.Featurebase.q || []).push(arguments);
                    };

                // Set widget as initialized once script is loaded
                setWidgetInitialized(true);
            };

            document.body.appendChild(featurebaseSDKScript);

            return () => {
                const script = document.getElementById('featurebase-sdk');
                if (script) {
                    document.body.removeChild(script);
                }
            };
        } else {
            setWidgetInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (isWidgetInitialized && customer_id && !document.querySelector('[data-featurebase-widget]')) {
            // @ts-ignore
            window.Featurebase('initialize_portal_widget', {
                organization: 'pingproxies',
                placement: 'right',
                fullScreen: true,
                initialPage: 'HelpView',
                locale: 'en',
                metadata: { customer_id: customer_id.toString() },
            });

            // @ts-ignore
            window.Featurebase('initialize_feedback_widget', {
                organization: 'pingproxies',
                theme: 'dark',
                locale: 'en',
                metadata: { "customer_id": customer_id.toString() },
            });
        }
    }, [isWidgetInitialized, customer_id]);

    return null;
};

export default FeaturebaseWidget;
