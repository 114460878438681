import { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import './styles.scss';
import { ReactComponent as Icon1 } from 'icons/colored/statistic-bar-3.svg';
import { ReactComponent as Icon2 } from 'icons/colored/statistic-bar-2.svg';
import { ReactComponent as Icon3 } from 'icons/colored/statistic-bar-1.svg';
import StatisticItem from './StatisticItem';
import { selectCustomer } from 'store/slices/customer';
import { convertToDollar } from 'helpers/payments';

import ROUTES from 'helpers/routes';
import ModalTopUp from 'components/Modals/ModalTopUp';
import Label from 'components/_common/Label';
import ModalReconfigureService from 'components/Modals/ModalReconfigureService';
import ServicesService from 'api/services/ServicesService';

const StatisticBar = () => {
    const { residential_bytes_left, credit_balance, proxy_count, active_residential_service_id } =
        useSelector(selectCustomer);

    const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);
    const [isTopUpLoading, setIsTopUpLoading] = useState(false);
    const [topUpData, setTopUpData] = useState(null);

    const handleTopUp = async () => {
        setIsTopUpModalOpen(true);
        setIsTopUpLoading(true);
        try {
            await ServicesService.reconfigureCalculate({ service_id: active_residential_service_id })
                .then(response => {
                    setTopUpData({ ...response.data, service_id: active_residential_service_id });
                })
                .finally(() => {
                    setIsTopUpLoading(false);
                });
        } catch (error) {
            return false;
        }
    };

    const formatBandwidth = bytes => {
        if (!bytes) {
            return '0 MB';
        }
        const units = ['MB', 'GB', 'TB', 'PB'];
        let unitIndex = 0;
        let value = bytes / (1024 * 1024) || 0; // Start with MB

        while (value >= 1000 && unitIndex < units.length - 1) {
            value /= 1024;
            unitIndex++;
        }

        return `${value.toFixed(2)} ${units[unitIndex]}`;
    };

    const convertedValue = formatBandwidth(residential_bytes_left);
    const convertedBalance = convertToDollar(credit_balance);

    const items = [
        {
            title: 'Proxies Number',
            tooltip: 'The number of static proxies assigned to your account',
            value: proxy_count,
            icon: <Icon1 />,
            action: (
                <NavLink to={proxy_count === 0 ? ROUTES.products : ROUTES.proxyList} replace={true}>
                    <Label color="green" text={proxy_count === 0 ? 'Buy Proxies' : 'View'} />
                </NavLink>
            ),
        },
        {
            title: 'Residential Bandwidth',
            tooltip: 'The amount of available residential bandwidth left on your account',
            value: convertedValue,
            icon: <Icon2 />,
            action: active_residential_service_id ? (
                <Label onClick={handleTopUp} color="green" text="Add Bandwidth" />
            ) : (
                <NavLink to={ROUTES.residential} replace={true}>
                    <Label color="green" text="Generate" />
                </NavLink>
            ),
        },
        {
            title: 'Balance',
            tooltip: 'The amount of store credit available on your account',
            value: convertedBalance,
            icon: <Icon3 />,
            action: <ModalTopUp labelButton={true} />,
        },
    ];

    return (
        <>
            <div className="statistic-bar">
                {items.map(props => (
                    <StatisticItem key={props.title} {...props} />
                ))}
            </div>
            <ModalReconfigureService
                setOpen={setIsTopUpModalOpen}
                open={isTopUpModalOpen}
                existingSecondData={topUpData}
                isExistingLoading={isTopUpLoading}
                isResidential={true}
                title="Top Up Now"
                successMessage="Top Up is complete"
            />
        </>
    );
};

export default StatisticBar;
