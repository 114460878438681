import { useEffect } from 'react';

const MavaWidget = () => {
    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');

        script.setAttribute('src', 'https://widget.mava.app');
        script.setAttribute('id', 'MavaWebChat');
        script.setAttribute('widget-version', 'v2');
        script.setAttribute('enable-sdk', 'true');
        script.setAttribute('data-token', 'a292820e4928b4feab721ae1ed915ee3f1970d7ae04fcb40018720198d253b56');
        script.setAttribute('data-hide-mava-launcher', 'true');
        head.appendChild(script);

        return () => {
            head.removeChild(script);
        };
    }, []);

    return null;
};

export default MavaWidget;
