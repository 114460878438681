import CityDropdown from 'components/CityDropdown';
import CountriesDropdown from 'components/CountryDropdown';
import IspAsnDropdown from 'components/IspAsnDropdown';
import ProxyUsersDropdown from 'components/ProxyUserDropdown';
import Button from 'components/_common/Button';
import RadioGroup from 'components/_common/RadioGroup';
import TextInput from 'components/_common/TextInput';
import { withoutEmptyValues } from 'helpers';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectResidentialListLoading, fetchResidentialList } from 'store/slices/residentialLedger';
import ModalGenerateProxyWarning from 'components/Modals/ModalGenerateProxyWarning';

const type_options = [
    { value: 'sticky', label: 'Sticky' },
    { value: 'rotating', label: 'Rotating' },
];

const GenerateProxyForm = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectResidentialListLoading);

    const [type, setType] = useState(type_options[0].value);
    const [state, setState] = useState({ country: '', city: '', asn: '', proxyUser: '' });
    const [quantity, setQuantity] = useState('');
    const [openWarningModal, setOpenWarningModal] = useState(false);

    const handleCoutryChange = value => setState({ ...state, city: '', country: value });
    const handleCityChange = value => {
        if (!state.city && value) {
            setOpenWarningModal(true);
        }
        setState({ ...state, city: value });
    };
    const handleASNChange = value => {
        if (!state.asn && value) {
            setOpenWarningModal(true);
        }
        setState({ ...state, asn: value });
    };
    const handleProxyUserChange = value => setState({ ...state, proxyUser: value });
    const handleChangeQuantity = value => value <= 10000 && setQuantity(value);

    const { country, city, asn, proxyUser } = state;

    const handleSubmit = () => {
        const params = withoutEmptyValues({
            list_session_type: type,
            country_id: country,
            city_id: city,
            asn_id: asn,
            proxy_user_id: proxyUser,
            list_count: quantity,
        });

        // @ts-ignore
        dispatch(fetchResidentialList(params));
    };

    return (
        <div className="generate-proxy-form">
            <h6>Generate residential proxies</h6>
            <RadioGroup row label="Type" items={type_options} value={type} onChange={setType} />
            <CountriesDropdown value={country} onChange={handleCoutryChange} />
            <CityDropdown value={city} onChange={handleCityChange} countryCode={country} disabled={!country} />
            <IspAsnDropdown value={asn} onChange={handleASNChange} countryCode={country} />
            <ProxyUsersDropdown value={proxyUser} onChange={handleProxyUserChange} />
            <TextInput label="Quantity" value={quantity} onChange={handleChangeQuantity} placeholder="1-10000" />
            <Button size="large" fullWidth onClick={handleSubmit} loading={loading}>
                Generate
            </Button>
            {openWarningModal && <ModalGenerateProxyWarning open={openWarningModal} setOpen={setOpenWarningModal} />}
        </div>
    );
};

export default GenerateProxyForm;
